import { BatchHttpLink } from '@apollo/client/link/batch-http'

export const getHttpLink = (config) =>
  new BatchHttpLink({
    uri: `${config.BFF_BASE_URI}/graphql`,
    credentials: 'same-origin',
    headers: {
      'Toast-Restaurant-External-ID': config.RESTAURANT_GUID
    }
  })
